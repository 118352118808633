import Vue from "vue";
import VueRouter from "vue-router";
import { setI18nLanguage } from "@/i18n";
import store from "@/store/index";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((__, _, next) => {
  setI18nLanguage(store.getters["options/getActiveLocale"]);
  next();
});

export default router;
