import store from "@/store/index";
import router from "@/router/index";
export class SnackBar {
  static openSuccessSnackbar(response) {
    let newSnackbar = {
      active: true,
      response: response,
      color: "success",
    };
    //store.state.snackbar = newSnackbar
    store.commit("_setSnackbar", newSnackbar);
  }
  static openErrorSnackbar(response) {
    let newSnackbar = {
      active: true,
      response: response,
      color: "red",
    };
    //store.state.snackbar = newSnackbar
    store.commit("_setSnackbar", newSnackbar);
  }
}

export class HandleApiCallResponse {
  //Common error => 401, 404, 500
  static handleCommonError(error, backViewName, parameterName, parameterValue) {
    let response = "";
    if (error.response.status === 401) {
      store.commit("login/logout");
      router.push({ name: "login" });
      response = "Session is expired, please login";
    } else if (error.response.status === 404) {
      if (backViewName) {
        let objectToPushTo = { name: backViewName };
        if (parameterName && parameterValue) {
          objectToPushTo[parameterName] = parameterValue;
        }
        router.push(objectToPushTo);
      }
      response = error.response.data;
    } else {
      response = "Try Later, internal server error";
    }

    SnackBar.openErrorSnackbar(response);
  }

  static handleSuccess(successMessage) {
    SnackBar.openSuccessSnackbar(successMessage);
  }
}
