export default [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => {
      return import(/* webpackChunkName: "home" */ "@/views/HomeView.vue");
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => {
      return import(/* webpackChunkName: "login" */ "@/views/LoginView.vue");
    },
    meta: {
      hideSidebar: true,
    },
  },
  {
    path: "/clients",
    name: "clients",
    component: () => {
      return import(
        /* webpackChunkName: "clients" */ "@/views/clients/ClientsView.vue"
      );
    },
  },
  {
    path: "/clients/:idClient",
    name: "client",
    component: () => {
      return import(
        /* webpackChunkName: "clients" */ "@/views/clients/ClientSingleView.vue"
      );
    },
    props: true,
  },
  {
    path: "/mngm/clients/create",
    name: "client-create",
    component: () => {
      return import(
        /* webpackChunkName: "clients" */ "@/views/clients/ClientCreateView.vue"
      );
    },
    props: true,
  },
  {
    path: "/orders",
    name: "orders",
    component: () => {
      return import(
        /* webpackChunkName: "orders" */ "@/views/orders/OrdersView.vue"
      );
    },
  },
  {
    path: "/orders/:orderId(\\d+)",
    name: "order",
    component: () => {
      return import(
        /* webpackChunkName: "orders" */ "@/views/orders/SingleOrderView.vue"
      );
    },
  },
  {
    path: "/order-items/:itemId(\\d+)",
    name: "order-item",
    component: () => {
      return import(
        /* webpackChunkName: "orders" */ "@/views/orders/OrderItemView.vue"
      );
    },
  },
  {
    path: "/mngm/orders/create",
    name: "order-create",
    component: () => {
      return import(
        /* webpackChunkName: "orders" */ "@/views/orders/OrderCreateView.vue"
      );
    },
  },
  {
    path: "/mngm/order-items/create/:orderId",
    name: "order-items-create",
    component: () => {
      return import(
        /* webpackChunkName: "orders" */ "@/views/orders/OrderItemsCreateView.vue"
      );
    },
    props: true,
  },
  {
    path: "/mngm/order-items/edit/:itemId",
    name: "order-item-edit",
    component: () => {
      return import(
        /* webpackChunkName: "orders" */ "@/views/orders/OrderItemEdit.vue"
      );
    },
    props: true,
  },
  {
    path: "/gantt",
    name: "gantt",
    component: () => {
      return import(/* webpackChunkName: "gantt" */ "@/views/GanttView.vue");
    },
    props: true,
  },
  {
    path: "/suppliers",
    name: "suppliers",
    component: () => {
      return import(
        /* webpackChunkName: "suppliers" */ "@/views/suppliers/SupplierList.vue"
      );
    },
  },
  {
    path: "/suppliers/:supplierId",
    name: "supplier",
    component: () => {
      return import(
        /* webpackChunkName: "suppliers" */ "@/views/suppliers/SupplierView.vue"
      );
    },
    props: true,
  },
  {
    path: "/mngm/suppliers/create",
    name: "supplier-create",
    component: () =>
      import(
        /* webpackChunkName: "supplier-create" */ "@/views/suppliers/SupplierCreate.vue"
      ),
  },
  {
    path: "/purchase-products",
    name: "purchase-products",
    component: () =>
      import(
        /* webpackChunkName: "purchases" */ "@/views/products/PurchaseProductsView.vue"
      ),
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/ProductListView.vue"
      ),
  },
  {
    path: "/product-categories",
    name: "product-categories",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/ProductCategories.vue"
      ),
  },
  {
    path: "/mngm/products/create",
    name: "product-create",
    component: () =>
      import(
        /* webpackChunkName: "product-create" */ "@/views/products/ProductManageView.vue"
      ),
    props: true,
  },
  {
    path: "/mngm/products/:productId/edit",
    name: "product-edit",
    component: () =>
      import(
        /* webpackChunkName: "product-create" */ "@/views/products/ProductManageView.vue"
      ),
    props: true,
  },
  {
    path: "/purchase-categories",
    name: "purchase-categories",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/purchases/PurchaseCategories.vue"
      ),
  },
  {
    path: "/warehouses",
    name: "warehouses",
    component: () =>
      import(
        /* webpackChunkName: "warehouses" */ "@/views/warehouses/WarehouseListView.vue"
      ),
  },
  {
    path: "/warehouse/create",
    name: "warehouse-create",
    component: () =>
      import(
        /* webpackChunkName: "warehouse-create" */ "@/views/warehouses/WarehouseManageView.vue"
      ),
    props: true,
  },
  {
    path: "/warehouse/:warehouseId/edit",
    name: "warehouse-edit",
    component: () =>
      import(
        /* webpackChunkName: "warehouse-create" */ "@/views/warehouses/WarehouseManageView.vue"
      ),
    props: true,
  },
  {
    path: "/purchases",
    name: "purchases",
    component: () => {
      return import(
        /* webpackChunkName: "purchases" */ "@/views/purchases/PurchaseListView.vue"
      );
    },
  },
  {
    path: "/purchases/:purchaseId",
    name: "purchase",
    component: () => {
      return import(
        /* webpackChunkName: "purchases" */ "@/views/purchases/PurchaseView.vue"
      );
    },
    props: true,
  },
  {
    path: "/mngm/purchases/create",
    name: "purchase-create",
    component: () =>
      import(
        /* webpackChunkName: "purchase-create" */ "@/views/purchases/PurchaseCreate.vue"
      ),
    props: true,
  },
  {
    path: "/inventory",
    name: "inventory",
    component: () => {
      return import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/TotalInventoryView.vue"
      );
    },
  },
  {
    path: "/inventory/product/:productId/code/:productCode/warehouse/:warehouseId",
    name: "single-inventory",
    component: () => {
      return import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/SingleInventoryGroupView.vue"
      );
    },
    props: true,
  },
  {
    path: "/marketing/rankings",
    name: "rankings",
    component: () => {
      return import(
        /* webpackChunkName: "marketing" */ "@/views/marketing/ClientsRankingView.vue"
      );
    },
  },
  {
    path: "/marketing/options",
    name: "marketing-options",
    component: () => {
      return import(
        /* webpackChunkName: "marketing" */ "@/views/marketing/MarketingOptionsView.vue"
      );
    },
  },
  {
    path: "/options",
    name: "options",
    component: () => {
      return import(
        /* webpackChunkName: "options" */ "@/views/OptionsView.vue"
      );
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => {
      return import(/* webpackChunkName: "users" */ "@/views/UsersView.vue");
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => {
      return import(
        /* webpackChunkName: "reset-password" */ "@/views/ResetPassword.vue"
      );
    },
  },
  {
    path: "/confirm-reset-password/:token",
    name: "confirm-reset-password",
    component: () => {
      return import(
        /* webpackChunkName: "reset-password" */ "@/views/ConfirmResetPassword.vue"
      );
    },
    props: true,
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => {
      return import(
        /* webpackChunkName: "notifications" */ "@/views/notifications/NotificationsView.vue"
      );
    },
  },
  {
    path: "/notifications/:emailId(\\d+)/edit",
    name: "email-edit",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/views/notifications/EmailEditView.vue"
      ),
    props: true,
  },
  {
    path: "/notifications/:emailId(\\d+)/details",
    name: "email-view",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/views/notifications/EmailDetailsView.vue"
      ),
    props: true,
  },
];
